<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>

export default {}
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
#app,
html,
body {
  width: 100%;
  height: 100%;
  //font-family: "苹方" !important;
}
a {
  color: #333;
  text-decoration: none;
}
li {
  list-style: none;
}
// 设置整个项目的背景色
page {
  height: 100%;
  font-size: 14px;
  -webkit-user-select: text;
  user-select: text;
}
.example-info {
  font-size: 14px;
  color: #333;
  padding: 10px;
}

ul li {
  list-style: none;
}
</style>
