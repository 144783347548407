import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/homeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/doctorDetail',
    name: 'doctorDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/doctorDetail.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../components/login/order_login')
  },
  {
    path: '/getVer',
    name: 'getVer',
    component: () => import(/* webpackChunkName: "about" */ '../components/login/getVer')
  },
  {
    path: '/HealthRecords',
    name: 'HealthRecords',
    component: () => import(/* webpackChunkName: "about" */ '../views/HealthRecords')
  },
  {
    path: '/editArchives',
    name: 'editArchives',
    component: () => import(/* webpackChunkName: "about" */ '../views/editArchives')
  },
  {
    path: '/addArchives',
    name: 'addArchives',
    component: () => import(/* webpackChunkName: "about" */ '../views/addArchives')
  },
  {
    path: '/illnessReadme',
    name: 'illnessReadme',
    component: () => import(/* webpackChunkName: "about" */ '../views/illnessReadme')
  },
  {
    path: '/chatIM',
    name: 'chatIM',
    component: () => import(/* webpackChunkName: "about" */ '../components/chatIM/chatIM'),
    meta: {
      title: "",
      auth: false,
      keepAlive: true
    }
  },
  // {
  //   path: '/followup',
  //   name: 'followup',
  //   component: () => import(/* webpackChunkName: "about" */ '../components/chatIM/followup')
  // },
  // {
  //   path: '/prescriptionlist',
  //   name: 'prescriptionlist',
  //   component: () => import(/* webpackChunkName: "about" */ '../components/chatIM/prescriptionlist')
  // },
  {
    path: '/surveyAnswer',
    name: 'surveyAnswer',
    component: () => import(/* webpackChunkName: "about" */ '../components/chatIM/surveyAnswer')
  },
  {
    path: '/surveyDetail',
    name: 'surveyDetail',
    component: () => import(/* webpackChunkName: "about" */ '../components/chatIM/surveyDetail')
  },
]

const router = new VueRouter({
  routes,
  mode: 'history',
})

export default router
