import axios from 'axios'
import VUE_APP_API_IAPETUS_URL from './getAPI/iapetus'

let apiURL = ''
if (process.env.NODE_ENV === "development") {
  // alert("开发环境");
  // apiURL = 'https://iapetus.haiweikexin.com/api/v1/'
  apiURL = 'http://proxy.jiankanghao.net:50206/api/v1/'
  // apiURL = 'http://192.168.0.209:8301/api/v1/'
}else {
  // alert("生产环境");
  apiURL = VUE_APP_API_IAPETUS_URL
}

let http = axios.create({
  timeout: 12000,
  baseURL: apiURL,
  headers: {'Content-Type': 'application/json'}
});

export default http;
