import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);
import ElementUI from 'element-ui'; //element-ui的全部组件
Vue.use(ElementUI) //使用elementUI
Vue.config.productionTip = false
// import VConsole from 'vconsole';
// new VConsole();
new Vue({
  router,
  store, strict: process.env.NODE_ENV !== 'production',
  render: h => h(App)
}).$mount('#app')
