<template>
  <div class="ba_Home">
    <div class="ba_header">

      <div class="head_Info">
        <dl class="doctors_info">
          <dt><img src="../assets/imgs/宝安中医@2x.png" alt=""></dt>
          <dd>
            <p>深圳市宝安纯中医治疗医院</p>
            <p>三级医院 | 医院 | 其他</p>
          </dd>
        </dl>
        <div class="address_phone">
          <div class="address_left">
            <dl>
              <dt><img src="../assets/imgs/地址@2x.png" alt=""></dt>
              <dd>深圳市宝安区西乡街道来安路99号</dd>
            </dl>
          </div>
          <div class="address_right">
            <dl>
              <dt><img src="../assets/imgs/电话小备份@2x.png" alt=""></dt>
              <dd>
                <p>0755-23288773</p>
                <p>0755-23280785</p>
              </dd>
            </dl>
          </div>
        </div>
        <div class="hospital_Img">
          <img src="../assets/imgs/矩形@2x.png" alt="">
        </div>
      </div>
    </div>
    <div class="about_title">
      <dl class="jianjie">
        <dt><img src="../assets/imgs/重症专区@2x.png" alt=""></dt>
        <dd>医院简介</dd>
      </dl>
      <dl class="see_all" @click="see_all">
        <dd>查看更多</dd>
        <dt><img src="../assets/imgs/更多.png" alt=""></dt>
      </dl>
    </div>
    <div class="hospital_Text">
      ● 深圳市宝安纯中医治疗医院位于深圳市宝安区西乡街道来安路99号，占地2.6万平方米，设诊室53间，病床…
    </div>
    <div class="about_title">
      <dl class="jianjie">
        <dt><img src="../assets/imgs/重症专区@2x.png" alt=""></dt>
        <dd>医生推荐</dd>
      </dl>

    </div>
    <div class="doctor_List">
      <div class="doctors_li" v-for="(item,index) in doctorList" @click="goDoctor_detail(item)" :key="index">
        <div class="doctors_img">
          <img :src="item.avatar" alt="">
        </div>
        <div class="doctor_info">
          <div class="doctor_name">
            <p class="d_name">{{ item.name }}</p>

          </div>
          <div class="doctor_tag">
            {{ item.department }} |
            {{ item.title }}
          </div>
          <div class="doctor_skill">
            擅长: {{ item.skill ? item.skill : '暂无' }}
          </div>
          <div class="doctor_rate">
            <div>接诊率 {{ item.diagnose_rate }}</div>
            <div>好评率 {{ item.favorable_rate }}</div>
            <div>咨询量 {{ item.diagnosed_count }}</div>
          </div>
          <div class="doctor_title" v-if="item.tags.length>0">
            <div class="jinpai" v-for="(items,indexs) in item.tags" :key="indexs">
              {{ items }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-popup v-model="see_Show" position="bottom" :style="{ height: '70%' }">
      <div class="see">
        <div class="see_title">
          <dl class="jianjie">
            <dt><img src="../assets/imgs/重症专区@2x.png" alt=""></dt>
            <dd>医院简介</dd>
          </dl>
          <dl class="see_all" @click="see_all">
            X
          </dl>
        </div>
        <div class="see_box">
          <p>
            •深圳市宝安纯中医治疗医院位于深圳市宝安区西乡街道来安路99号，占地2.6万平方米，设诊室53间，病床205张。建筑采用颇具中医药文化元素的汉唐风格，于2019年3月18日建成开业，在全国中医药界和社会各界引起热烈反响，是全国首家纯中医治疗医院，宝安区卫生健康局下属事业单位，为区属公立医院。
          </p>
          <p>
            •医院的建设旨在推动中医药事业传承、创新、发展，让中医、中医院、中医师回溯本源、回归本位，同时给老百姓提供多一种医疗供给、医疗服务、医疗选择。医院坚持“大门诊、小住院”建设模式，确定“5+5+3”业务布局规划，在住院部开设针灸科、内科、骨伤科、肿瘤科、妇科5个重点专科，门诊部设置儿科、皮肤科、治未病中心、国医堂和国际港澳台中医师诊疗部等5个特色科室，药学部设立中药质量检测控制室、中药临方制剂室、中药临方炮制室3个中药工作室，为患者提供优质高效的纯中医诊疗服务。
          </p>
          <p>
            •纯中医治疗医院得到国家和省、市大力支持和悉心指导。国家中医药管理局余艳红书记强调，深圳要站在“双区叠加”的高度支持宝安纯中医治疗医院先行先试，推进中医药事业创新发展。省卫生健康委段宇飞主任、省中医药局徐庆锋局长对纯中医治疗医院建设给予鼎立支持。深圳市委市政府非常关心宝安纯中医治疗医院发展，王伟中书记批示要求支持纯中医治疗医院建设，深圳市卫生健康委率先出台全国首部纯中医治疗医院设置标准。宝安区委区政府发起成立首个区级中医药发展基金会，为医院建设运营提供强有力支撑。医院的成立吸引了一大批中医药专家大师、中医药院校和机构来院开展合作与交流，共同促进医院可持续发展。
          </p>
          <p>
            •中医药事业创新发展任重而道远，宝安纯中医治疗医院将紧握深圳建设社会主义先行示范区的时代机遇，立足湾区核心区位优势，探索纯中医治疗新路径，传播推广中医药文化，走出一条中医药传承创新发展之路，谱写“健康中国”先行示范新篇章。
          </p>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import http from "@/utils/iapetusApiConfig";

export default {
  data() {
    return {
      doctorList: [],
      see_Show: false,
    }
  },
  mounted() {
    http.get('studios/7/doctors?page_size=20').then(res => {
      if (res.data.status_code == 200) {
        console.log(res.data.data)
        this.doctorList = res.data.data.items
      } else {
        this.$message.error(res.data.msg)
      }
    })
  },
  methods: {
    goDoctor_detail(item) {
      this.$router.push({
        name: 'doctorDetail',
        query: {
          id: item.id
        }
      })
    },
    see_all() {
      this.see_Show = !this.see_Show
    },
  }
}
</script>

<style lang="scss" scoped>
.ba_Home {
  width: 100%;
  height: 100%;
  overflow: auto;

  .ba_header {
    width: 100%;
    height: 21rem;
    background: url("../assets/imgs/aabb.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;

    .head_Title {
      width: 100%;
      height: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.13rem;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      color: #333333;
    }

    .head_Info {
      flex: 1;
      padding: 0.94rem;
      box-sizing: border-box;

      .doctors_info {
        width: 100%;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;

        dt {
          width: 2rem;
          height: 3rem;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 2rem;
            height: 2rem;
          }
        }

        dd {
          flex: 1;
          margin-left: .61rem;

          p:nth-child(1) {
            font-size: 1rem;
            font-family: PingFangSC, PingFang SC;
            font-weight: bold;
            color: #333333;
          }

          p:nth-child(2) {
            margin-top: .33rem;
            font-size: 0.75rem;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #285D93;
          }
        }
      }

      .address_phone {
        width: 100%;
        height: 2.75rem;
        margin-top: .63rem;
        display: flex;
        justify-content: space-between; /*左右分散排列*/
        align-items: center; /*垂直居中*/
        .address_left {
          width: 48%;
          height: 2.75rem;
          background: #E2EAF3;
          border-radius: 0.25rem;

          dl {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: .44rem;
            box-sizing: border-box;

            dt {
              width: 1.25rem;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;

              img {
                width: 1.25rem;
                height: 1.25rem;
              }
            }

            dd {
              font-size: 0.69rem;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              color: #74787E;
              margin-left: .31rem;
            }
          }
        }

        .address_right {
          width: 48%;
          height: 2.75rem;
          background: #E2EAF3;
          border-radius: 0.25rem;

          dl {
            display: flex;
            align-items: center;
            padding: .44rem;
            box-sizing: border-box;

            dt {
              width: 1.25rem;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;

              img {
                width: 1.25rem;
                height: 1.25rem;
              }
            }

            dd {
              font-size: 0.69rem;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              color: #74787E;
              margin-left: .31rem;
            }
          }
        }
      }

      .hospital_Img {
        width: 100%;
        height: 11rem;
        margin-top: .63rem;

        img {
          width: 100%;
          height: 11rem;
        }
      }
    }
  }

  .about_title {
    width: 100%;
    height: 2rem;
    display: flex;
    justify-content: space-between; /*左右分散排列*/
    align-items: center; /*垂直居中*/
    background: #F4F4F4;
    padding-left: .94rem;
    padding-right: .94rem;
    box-sizing: border-box;

    .jianjie {
      width: 10rem;
      display: flex;
      justify-content: left;
      align-items: center;

      dt {
        width: 1.3rem;
        height: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 1rem;
          height: 1rem;
        }
      }

      dd {
        font-size: 0.88rem;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #333333;
      }
    }

    .see_all {
      width: 10rem;
      display: flex;
      justify-content: right;
      align-items: center;

      dt {
        width: 1.3rem;
        height: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 1rem;
          height: 1rem;
        }
      }

      dd {
        font-size: 0.75rem;
        font-family: PingFangSC, PingFang SC;
        color: #999;
      }
    }
  }

  .hospital_Text {
    width: 100%;
    padding: .94rem .94rem 1.5rem;
    box-sizing: border-box;
    font-size: 0.88rem;
    font-family: LucidaGrande;
    color: #333333;
  }

  .doctor_List {
    width: 100%;
    height: auto;
    padding: .94rem;
    box-sizing: border-box;

    .doctors_li {
      width: 100%;
      height: auto;
      padding-bottom: 1rem;
      display: flex;

      .doctors_img {
        width: 3.5rem;
        height: 100%;
        display: flex;
        justify-content: center;

        img {
          width: 3.5rem;
          height: 3.5rem;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      .doctor_info {
        flex: 1;
        margin-left: .63rem;
        box-sizing: border-box;
        padding-bottom: .75rem;
        box-sizing: border-box;
        border-bottom: 1px solid #f4f4f4;

        .doctor_name {
          width: 100%;
          height: 1.4rem;
          display: flex;
          justify-content: space-between;

          .d_name {
            width: 8rem;
            font-size: 1rem;
            font-family: PingFangSC, PingFang SC;
            font-weight: bold;
            color: #333333;
          }

          .d_price {
            display: flex;
            justify-content: right;
            align-items: flex-end;
            width: 8rem;
            font-size: 0.88rem;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            color: #4B91C8;

            span {
              height: 1.75rem;
              margin-right: .2rem;
              margin-left: .2rem;
              font-size: 1.5rem;
            }
          }
        }

        .doctor_tag {
          margin-top: .25rem;
          width: 100%;
          height: 1.06rem;
          font-size: 0.75rem;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 1.06rem;
        }

        .doctor_skill {
          margin-top: .43rem;
          height: auto;
          font-size: 0.88rem;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #333333;
          overflow: hidden; //超出文本隐藏
          text-overflow: ellipsis; ///超出部分省略号显示
          display: -webkit-box; //弹性盒模型
          -webkit-box-orient: vertical; //上下垂直
          -webkit-line-clamp: 3; //自定义行数
        }

        .doctor_rate {
          width: 100%;
          height: 1.06rem;
          font-size: 0.75rem;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 1.06rem;
          display: flex;
          margin-top: .5rem;

          div {
            flex: 1;
          }
        }

        .doctor_title {
          width: 100%;
          height: 1.25rem;
          margin-top: .55rem;
          display: flex;
          flex-wrap: wrap;
          .jinpai {
            width: 3.38rem;
            height: 1rem;
            background: #FAF4F2;
            border-radius: 0.13rem;
            font-size: 0.63rem;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            color: #D8827A;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: .4rem;
          }
        .jinpai:nth-child(1){
          margin-left: 0 !important;
        }
        }
      }
    }
  }

  .see {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .see_title {
      width: 100%;
      height: 2.75rem;
      background: #F4F4F4;
      display: flex;
      justify-content: space-between; /*左右分散排列*/
      align-items: center; /*垂直居中*/
      background: #F4F4F4;
      padding-left: .94rem;
      padding-right: .94rem;
      box-sizing: border-box;

      .jianjie {
        width: 10rem;
        display: flex;
        justify-content: left;
        align-items: center;

        dt {
          width: 1.3rem;
          height: 2rem;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 1rem;
            height: 1rem;
          }
        }

        dd {
          font-size: 0.88rem;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: #333333;
        }
      }

      .see_all {
        width: 10rem;
        display: flex;
        justify-content: right;
        align-items: center;

        dt {
          width: 1.3rem;
          height: 2rem;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 1rem;
            height: 1rem;
          }
        }

        dd {
          font-size: 0.88rem;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: #999;
        }
      }
    }

    .see_box {
      flex: 1;
      overflow: scroll;
      padding: .94rem;
      box-sizing: border-box;
    }
  }
}
</style>